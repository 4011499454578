import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ChangeEvent, useState } from 'react';
import TextTruncate from 'react-text-truncate';

import { SectionContainer } from '@components/Layout/Layout.styles';

import { handleThumbnail } from '@helpers/imageHelper';
import { InputAdornment } from '@mui/material';

import {
  CardMediaWrapper,
  CardWrapper,
  HeaderWrapper,
  ImageWrapper,
  InsightAuthorName,
  InsightsListWrapper,
  InsightAuthorThumbnail,
  InsightData,
  InsightDate,
  InsightDiv,
  InsightHeader,
  InsightInfo,
  InsightLink,
  InsightPage,
  InsightReadTime,
  InsightsWrapper,
  InsightText,
  InsightTextWrapper,
  InsightTitle,
  LinkWrapper,
  SearchAdornment,
  Searchbar,
  SearchBarWrapper,
  Separator,
  SubTitle,
  SubTitleWrapper,
  TextWrapper,
  Title,
  TitleWrapper,
  WrapperInput,
} from './styles';
import { IInsightsProps } from './types';

const InsightsPage = (props: IInsightsProps) => {
  const { insights, isMobile } = props;
  const { t } = useTranslation();
  const [insightFilter, setInsightFilter] = useState(insights);

  const handleFilterInsights = (event: ChangeEvent<HTMLInputElement>) => {
    const filter = insights.filter(
      (insight) =>
        insight.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
        insight.subtitle!.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setInsightFilter(filter);
  };

  return (
    <InsightPage>
      <SectionContainer>
        <InsightsWrapper>
          <HeaderWrapper>
            <TitleWrapper>
              <Title>{t('INSIGHTS:TITLE')}</Title>
            </TitleWrapper>
            <SubTitleWrapper>
              <SubTitle>{t('INSIGHTS:SUBTITLE')}</SubTitle>
              <SubTitle>{t('INSIGHTS:SUBTITLE_TWO')}</SubTitle>
            </SubTitleWrapper>
            <SearchBarWrapper>
              <WrapperInput>
                <Searchbar
                  placeholder="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchAdornment />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleFilterInsights}
                />
              </WrapperInput>
            </SearchBarWrapper>
          </HeaderWrapper>
          <InsightsListWrapper>
            {insightFilter.map((insight) => {
              const publishDate = new Date(insight.publishDate!);
              return (
                <InsightDiv>
                  <InsightLink href={'/blog/' + insight.path}>
                    <CardWrapper>
                      <ImageWrapper>
                        <CardMediaWrapper image={insight.image} />
                      </ImageWrapper>
                      <InsightTextWrapper>
                        <InsightHeader>
                          <InsightAuthorThumbnail src={handleThumbnail(insight.author!)} />
                          <InsightData>
                            <InsightAuthorName>{insight.author}</InsightAuthorName>
                            <InsightInfo>
                              <InsightDate>
                                {!isMobile && <Separator>{t('INSIGHTS:SEPARATOR')}</Separator>}
                                {publishDate.toLocaleString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                })}
                              </InsightDate>
                              <InsightReadTime>
                                <Separator>{t('INSIGHTS:SEPARATOR')}</Separator>
                                {insight.readtime!} {t('INSIGHTS:READ_TIME')}
                              </InsightReadTime>
                            </InsightInfo>
                          </InsightData>
                        </InsightHeader>
                        <TextWrapper>
                          <InsightTitle>
                            <TextTruncate
                              line={2}
                              element="p"
                              truncateText="..."
                              text={insight.title}
                            />
                          </InsightTitle>
                          <LinkWrapper>
                            <InsightText>
                              <TextTruncate
                                line={2}
                                element="p"
                                truncateText="..."
                                text={insight.subtitle}
                              />
                            </InsightText>
                          </LinkWrapper>
                        </TextWrapper>
                      </InsightTextWrapper>
                    </CardWrapper>
                  </InsightLink>
                </InsightDiv>
              );
            })}
          </InsightsListWrapper>
        </InsightsWrapper>
      </SectionContainer>
    </InsightPage>
  );
};
export default InsightsPage;
