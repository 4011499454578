import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { IInsight } from 'types/insight';
import { IInsightsData } from 'types/insight';

import InsightsPage from '@components/Insights';
import Layout from '@components/Layout';
import SEO from '@components/SEO';

import { PATHS } from '@global/urls';
import { getImageData, ParseImages } from '@helpers/imageHelper';
import { SIZES } from '@style/sizes';

function Insigths(props: IInsightsData) {
  const { data } = props;
  const images = ParseImages(data);
  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.TABLET_992}px)`,
  });

  const blogs = data.cms.blogs.data;
  const insights: IInsight[] = [];
  blogs.forEach((bl) => {
    if (bl.attributes.Title !== undefined) {
      insights.push({
        title: bl.attributes.Title,
        subtitle: bl.attributes.SubTitle,
        image: bl.attributes.CoverImage.data.attributes.url,
        path: bl.attributes.path,
        readtime: bl.attributes.readTime,
        publishDate: bl.attributes.publishedAt,
        author:
          bl.attributes.Author === 'Taylor_Gray_Ph_D'
            ? t('INSIGHTS:TAYLOR_AUTHOR_NAME')
            : t('INSIGHTS:KAI_AUTHOR_NAME'),
      });
    }
  });
  return (
    <Layout footerLogo={getImageData(images, 'footer')} currentRoute={PATHS.INSIGHT}>
      <SEO metaDescription={t('SEO:METADESCRIPTION')} metaTitle={t('SEO:METATITLE')} />
      <InsightsPage insights={insights} isMobile={isMobile} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    cms {
      blogs(pagination: { limit: 100 }, sort: "publishedAt:desc") {
        data {
          id
          attributes {
            Title
            Author
            publishedAt
            readTime
            Body {
              ... on cms_ComponentBlogBodyText2 {
                id
                RichText
              }
              ... on cms_ComponentBlogClarification {
                id
                Clarification
              }
              ... on cms_ComponentBlogImage {
                id
                Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on cms_ComponentBlogSubscribe {
                Subscribe
              }
            }
            CoverImage {
              data {
                attributes {
                  alternativeText
                  url
                }
                id
              }
            }
            SubTitle
            path
          }
        }
      }
    }
    allFile(
      filter: {
        relativeDirectory: {
          in: ["components/Layout/Topbar/assets", "components/Layout/Footer/assets"]
        }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["topbar", "footer", "demoModal", "insights", "SEO"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Insigths;
