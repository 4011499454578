import styled from 'styled-components';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, CardMedia } from '@mui/material';
import { SIZES } from '@style/sizes';

export const InsightPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.quinternary};
`;

export const InsightsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const InsightWrapper = styled.div`
  height: 600px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const InsightImage = styled.img`
  @media (max-width: ${SIZES.TABLET_992}px) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    width: 100%;
    height: 100%;
  }
  display: flex;
  justify-content: left;
`;

export const InsightTitle = styled.h2`
  @media (max-width: ${SIZES.TABLET_992}px) {
    padding: 15px 0 10px;
  }

  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  color: ${(props) => props.theme.palette.primary};
  font-size: 30px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;

  line-height: 36px;
  text-align: left;
`;

export const InsightLink = styled.a`
  width: 100%;
  text-decoration: none;
`;
export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 22px;
`;

export const InsightText = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  color: ${(props) => props.theme.palette.septernary};
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-align: left;
`;

export const Title = styled.h2`
  @media (max-width: ${SIZES.TABLET_992}px) {
    font-size: 36px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
  color: ${(props) => props.theme.palette.primary};
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 42px;
  line-height: 55px;
`;

export const SubTitle = styled.p`
  @media (max-width: ${SIZES.TABLET_992}px) {
    font-size: 16px;
  }
  text-align: center;
  width: 100%;
  color: ${(props) => props.theme.palette.septernary};
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 20px;
  line-height: 28px;
`;

export const SubTitleWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    height: 80px;
    align-items: center;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
`;

export const InsightDiv = styled.div`
  border: 1px solid ${(props) => props.theme.palette.tertiary};
  border-radius: 12px;
  text-decoration: none;
`;

export const CardWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    flex-direction: column;
  }
  border: 1px solid ${(props) => props.theme.palette.quinternary};
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  justify-content: left;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
`;

export const InsightHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
  align-self: flex-start;
`;
export const InsightsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;

export const InsightAuthorName = styled.div`
  color: ${(props) => props.theme.palette.septernary};
  white-space: nowrap;
  align-items: center;
  display: flex;
`;

export const InsightDate = styled.div`
  color: ${(props) => props.theme.palette.septernary};
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
`;

export const InsightAuthorThumbnail = styled.img`
  border-radius: 50px;
  width: 40px;
  padding-right: 10px;
  height: 40px;
`;

export const Separator = styled.div`
  font-size: 20px;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  color: ${(props) => props.theme.palette.quaternary};
`;

export const InsightTextWrapper = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    margin-top: 20px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 20px;
  box-sizing: border-box;
`;

export const InsightReadTime = styled.div`
  white-space: nowrap;
  display: flex;
  color: ${(props) => props.theme.palette.septernary};
  align-items: center;
`;

export const InsightInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
`;

export const CardMediaWrapper = styled(CardMedia)`
  @media (max-width: ${SIZES.TABLET_992}px) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 0;
    width: 100%;
  }
  height: 250px;
  width: 350px;
  border-radius: 6px;
  margin-right: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const SearchBarWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin: 30px 0;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff8f8;
`;

export const WrapperInput = styled.div`
  width: 100%;
  max-width: 470px;
  padding: 10px 0;
  box-sizing: border-box;
`;

export const Searchbar = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    border-radius: 100px;
    height: 46px;
    background-color: ${(props) => props.theme.palette.common.white};
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const SearchAdornment = styled(SearchIcon)`
  color: ${(props) => props.theme.palette.primary};
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InsightData = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    gap: 5px;
    flex-direction: column;
  }
  display: flex;
`;
